.player {
    align-items: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.player .item {
    padding: 4px 16px 16px;
    width: 60%;
    max-width: 500px;
    opacity: var(--opacity-fade);
    border-radius: 12px;
}

.player .item.item__active {
    background-color: var(--color-light);
    opacity: 1;
}

.player .item__title {
    text-align: right;
    color: var(--color-text);
    font-size: 80%;
    margin: 0 32px 4px;
}

.player .item audio {
    width: 100%;
}

@media(max-width: 400px) {
    .player .item {
        width: 90%;
    }
}