html {
  /* #393f4a */
  --color-light: #50535a;
  --color-text: #eeeeee;
  --color-dark: #282c34;
  --opacity-fade: 0.6;
}

html, .app {
  background-color: var(--color-dark);
}

.app {
  flex-direction: column;
  display: flex;
  min-height: 100vh;
  width: 100vw;
  padding: 0;
  margin: 0;
  justify-content: space-between;
  align-items: center;
  color: var(--color-text);
}

.app .main {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  margin-top: 50px;
  padding: 20px;
  width: calc(100% - 40px);
  height: 100%;
  justify-content: center;
  align-items: center;
}