.footer {
    font-size: 72%;
    margin-top: 16px;
    padding: 8px;
    text-align: center;
    width: 100%;
    border-top: 1px solid var(--color-light);
}

.footer .disclaimer {
    color: rgba(255, 255, 255, var(--opacity-fade));
}

.footer .disclaimer span {
    text-shadow: #aaa 0 0 0;
}

.footer a {
    text-decoration: none;
    color: rgba(255, 255, 255, var(--opacity-fade));
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: var(--color-light);
}
.footer a:hover {
    color: var(--color-text);
    border-color: var(--color-text);
}